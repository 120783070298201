<template>
<div class="wrapper" id="stewie">
    <div class="head">
        <div class="hair-wrapper">
            <div class="hair1"></div>
            <div class="hair2"></div>
            <div class="hair3"></div>
            <div class="hair4"></div>
            <div class="hair5"></div>
            <div class="hair6"></div>
            <div class="hair7"></div>
            <div class="hair8"></div>
            <div class="hair9"></div>
        </div>
        <div class="ear" id="left">
            <div class="ear-inside"></div>
        </div>
        <div class="ear-cover">
            <div class="ear-inside"></div>
        </div>
        <div class="eyebrow" id="left"></div>
        <div class="eyebrow" id="right"></div>
        <div class="eye" id="left">
            <div class="eyeball" id="eyeball-left"></div>
            <div class="eyelid" id="lower"></div>
            <div class="eyelid" id="upper"></div>
        </div>
        <!--
      <div class="eye" id="left" style="background-color:transparent"></div>
-->
        <div class="eye" id="right">
            <div class="eyeball" id="eyeball-right"></div>
            <div class="eyelid" id="lower"></div>
            <div class="eyelid" id="upper"></div>
        </div>
        <!--
      <div class="eye" id="right" style="background-color:transparent"></div>
-->
        <div class="nose"></div>
        <div class="mouth">
            <div class="upper-lip"></div>
            <div class="mouth-line"></div>
            <div class="lower-lip"></div>
        </div>
    </div>
    <div class="ear" id="right"></div>
    <div class="body">
        <div class="shirt" id="left"></div>
        <div class="shirt" id="right"></div>
        <div class="tummy"></div>
        <div class="arm-left"></div>
        <div class="overalls-main"></div>
        <div class="overalls-strap-left"></div>
        <div class="overalls-strap-left-cover"></div>
        <div class="overalls-strap-right"></div>
        <div class="overalls-side-right"></div>
        <div class="overalls-neck"></div>
        <div class="button-left"></div>
        <div class="button-right"></div>
        <div class="bok"></div>
        <div class="leg-left"></div>
        <div class="leg-left-bottom"></div>
        <div class="leg-middle"></div>
        <div class="leg-right"></div>
        <div class="leg-right-bottom"></div>
        <div class="shoe-right"></div>
        <div class="shoe-left"></div>
        <div class="hand-right">
            <div class="finger1"></div>
            <div class="finger2"></div>
        </div>
        <div class="hand-left">
            <div class="finger1"></div>
            <div class="finger2"></div>
            <div class="finger3"></div>
            <div class="palm"></div>
            <div class="finger4"></div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "Person2"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/*! https://codepen.io/aakashrodrigues/pen/ouspA */

// display: none turns animation off
.wrapper {
    display: none;
}

.person2 {
    .wrapper {
        display: block;
        position: fixed;
        width: 210px;
        height: 210px;
        left: 50%;
        bottom: 0;
        margin-left: -120px;
        // margin-top: -105px;
        z-index: 2001;
        pointer-events: none;
    }

    .head {
        width: 120px;
        height: 120px;
        background-color: antiquewhite;
        border-bottom-left-radius: 140px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 120px;
        border-top-left-radius: 25px;
        transform: rotate(-53deg);
        -webkit-transform: rotate(-53deg);
        -moz-transform: rotate(-53deg);
        -ms-transform: rotate(-53deg);
        -o-transform: rotate(-53deg);
        position: absolute;
        left: 50px;
        border: 2px solid #000;
        z-index: 5;
    }

    .hair-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .hair1 {
        width: 10px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-right-radius: 13px;
        position: absolute;
        top: -3px;
        left: 16px;
        transform: rotate(25deg);
        -webkit-transform: rotate(25deg);
        -moz-transform: rotate(25deg);
        -ms-transform: rotate(25deg);
        -o-transform: rotate(25deg);
    }

    .hair2 {
        width: 10px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-right-radius: 13px;
        position: absolute;
        top: -3px;
        left: 31px;
        transform: rotate(36deg);
        -webkit-transform: rotate(36deg);
        -moz-transform: rotate(36deg);
        -ms-transform: rotate(36deg);
        -o-transform: rotate(36deg);
    }

    .hair3 {
        width: 14px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-right-radius: 10px;
        position: absolute;
        top: 0px;
        left: 43px;
        transform: rotate(58deg);
        -webkit-transform: rotate(58deg);
        -moz-transform: rotate(58deg);
        -ms-transform: rotate(58deg);
        -o-transform: rotate(58deg);
    }

    .hair4 {
        width: 18px;
        height: 23px;
        border-top: 2px solid #000;
        border-top-right-radius: 14px;
        position: absolute;
        top: 5px;
        left: 55px;
        transform: rotate(64deg);
        -webkit-transform: rotate(64deg);
        -moz-transform: rotate(64deg);
        -ms-transform: rotate(64deg);
        -o-transform: rotate(64deg);
    }

    .hair5 {
        width: 18px;
        height: 23px;
        border-top: 2px solid #000;
        border-top-right-radius: 16px;
        position: absolute;
        top: 15px;
        left: 71px;
        transform: rotate(71deg);
        -webkit-transform: rotate(71deg);
        -moz-transform: rotate(71deg);
        -ms-transform: rotate(71deg);
        -o-transform: rotate(71deg);
    }

    .hair6 {
        width: 13px;
        height: 23px;
        border-top: 2px solid #000;
        border-top-right-radius: 16px;
        position: absolute;
        top: 29px;
        left: 89px;
        transform: rotate(80deg);
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
    }

    .hair7 {
        width: 13px;
        height: 23px;
        border-top: 2px solid #000;
        border-top-right-radius: 16px;
        position: absolute;
        top: 46px;
        left: 98px;
        transform: rotate(88deg);
        -webkit-transform: rotate(88deg);
        -moz-transform: rotate(88deg);
        -ms-transform: rotate(88deg);
        -o-transform: rotate(88deg);
    }

    .hair8 {
        width: 13px;
        height: 23px;
        border-top: 2px solid #000;
        border-top-right-radius: 16px;
        position: absolute;
        top: 66px;
        left: 104px;
        transform: rotate(93deg);
        -webkit-transform: rotate(93deg);
        -moz-transform: rotate(93deg);
        -ms-transform: rotate(93deg);
        -o-transform: rotate(93deg);
    }

    .hair9 {
        width: 10px;
        height: 23px;
        border-top: 2px solid #000;
        border-top-right-radius: 16px;
        position: absolute;
        top: 84px;
        left: 106px;
        transform: rotate(99deg);
        -webkit-transform: rotate(99deg);
        -moz-transform: rotate(99deg);
        -ms-transform: rotate(99deg);
        -o-transform: rotate(99deg);
    }

    .ear#left {
        width: 16px;
        height: 13px;
        background-color: antiquewhite;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 36px;
        border-top-left-radius: 40px;
        transform: rotate(-44deg);
        -webkit-transform: rotate(-44deg);
        -moz-transform: rotate(-44deg);
        -ms-transform: rotate(-44deg);
        -o-transform: rotate(-44deg);
        position: absolute;
        left: -6px;
        top: -5px;
        border: 2px solid #000;
        border-bottom-style: none;
        z-index: 2;
    }

    .ear#left .ear-inside {
        width: 8px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-left-radius: 7px;
        position: absolute;
        top: 2px;
        left: 0px;
        transform: rotate(37deg);
        -webkit-transform: rotate(37deg);
        -moz-transform: rotate(37deg);
        -ms-transform: rotate(37deg);
        -o-transform: rotate(37deg);
    }

    .ear#right {
        width: 16px;
        height: 17px;
        background-color: antiquewhite;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 45px;
        border-top-right-radius: 50px;
        border-top-left-radius: 40px;
        transform: rotate(-100deg);
        -webkit-transform: rotate(-100deg);
        -moz-transform: rotate(-100deg);
        -ms-transform: rotate(-100deg);
        -o-transform: rotate(-100deg);
        position: absolute;
        left: 182px;
        top: 42px;
        border: 2px solid #000;
        border-top-style: none;
        z-index: -1;
    }

    .ear-cover .ear-inside {
        width: 6px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-right-radius: 7px;
        position: absolute;
        top: 6px;
        left: -1px;
        transform: rotate(46deg);
        -webkit-transform: rotate(46deg);
        -moz-transform: rotate(46deg);
        -ms-transform: rotate(46deg);
        -o-transform: rotate(46deg);
    }

    .ear-cover {
        width: 13px;
        height: 16px;
        background-color: antiquewhite;
        border-bottom-left-radius: 45px;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        transform: rotate(-48deg);
        -webkit-transform: rotate(-48deg);
        -moz-transform: rotate(-48deg);
        -ms-transform: rotate(-48deg);
        -o-transform: rotate(-48deg);
        position: absolute;
        left: 108px;
        top: 108px;
        z-index: 2;
    }

    .eye#left {
        width: 29px;
        height: 27px;
        background-color: white;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;
        border-top-right-radius: 60px;
        border-top-left-radius: 60px;
        border: 2px solid;
        transform: rotate(-48deg);
        -webkit-transform: rotate(-48deg);
        -moz-transform: rotate(-48deg);
        -ms-transform: rotate(-48deg);
        -o-transform: rotate(-48deg);
        position: absolute;
        left: 15px;
        top: 25px;
        z-index: 2;
        overflow: hidden;
    }

    .eye#left .eyeball {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 3px;
        position: absolute;
        left: 10px;
        top: 13px;
        z-index: 3;
    }

    .eye#left .eyelid#lower {
        width: 25px;
        height: 1px;
        border-top: 2px solid;
        transform: rotate(96deg);
        -webkit-transform: rotate(96deg);
        -moz-transform: rotate(96deg);
        -ms-transform: rotate(96deg);
        -o-transform: rotate(96deg);
        position: absolute;
        left: -7px;
        top: 11px;
        z-index: 3;
        transition: all 200ms ease;
    }

    .eye#left .eyelid#lower:after {
        content: "";
        width: 25px;
        height: 25px;
        background-color: antiquewhite;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 3;
    }

    .eye#left .eyelid#upper {
        width: 25px;
        height: 1px;
        border-top: 2px solid;
        transform: rotate(110deg);
        -webkit-transform: rotate(110deg);
        -moz-transform: rotate(110deg);
        -ms-transform: rotate(110deg);
        -o-transform: rotate(110deg);
        position: absolute;
        left: 9px;
        top: 14px;
        z-index: 3;
        transition: all 200ms ease;
    }

    .eye#left .eyelid#upper:after {
        content: "";
        width: 25px;
        height: 25px;
        background-color: antiquewhite;
        position: absolute;
        left: 0px;
        bottom: 3px;
        z-index: 3;
    }

    .eye#right {
        width: 29px;
        height: 26px;
        background-color: white;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;
        border-top-right-radius: 60px;
        border-top-left-radius: 60px;
        border: 2px solid;
        transform: rotate(-228deg);
        -webkit-transform: rotate(-228deg);
        -moz-transform: rotate(-228deg);
        -ms-transform: rotate(-228deg);
        -o-transform: rotate(-228deg);
        position: absolute;
        left: 67px;
        top: 77px;
        z-index: 2;
        overflow: hidden;
    }

    .eye#right .eyeball {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 3px;
        position: absolute;
        left: 14px;
        top: 13px;
        z-index: 3;
    }

    .eye#right .eyelid#lower {
        width: 25px;
        height: 1px;
        border-top: 2px solid;
        transform: rotate(84deg);
        -webkit-transform: rotate(84deg);
        -moz-transform: rotate(84deg);
        -ms-transform: rotate(84deg);
        -o-transform: rotate(84deg);
        position: absolute;
        left: 10px;
        top: 11px;
        z-index: 3;
        transition: all 200ms ease;
    }

    .eye#right .eyelid#lower:after {
        content: "";
        width: 25px;
        height: 25px;
        background-color: antiquewhite;
        position: absolute;
        left: 0px;
        bottom: 3px;
        z-index: 3;
    }

    .eye#right .eyelid#upper {
        width: 25px;
        height: 1px;
        border-top: 2px solid;
        transform: rotate(69deg);
        -webkit-transform: rotate(69deg);
        -moz-transform: rotate(69deg);
        -ms-transform: rotate(69deg);
        -o-transform: rotate(69deg);
        position: absolute;
        left: -5px;
        top: 13px;
        z-index: 3;
        transition: all 200ms ease;
    }

    .eye#right .eyelid#upper:after {
        content: "";
        width: 25px;
        height: 25px;
        background-color: antiquewhite;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 3;
    }

    .eyebrow#right {
        width: 22px;
        height: 1px;
        border-top: 2px solid;
        transform: rotate(16deg);
        -webkit-transform: rotate(16deg);
        -moz-transform: rotate(16deg);
        -ms-transform: rotate(16deg);
        -o-transform: rotate(16deg);
        position: absolute;
        left: 85px;
        top: 73px;
        z-index: 3;
        transition: all 200ms ease;
    }

    .eyebrow#left {
        width: 22px;
        height: 1px;
        border-top: 2px solid;
        transform: rotate(64deg);
        -webkit-transform: rotate(64deg);
        -moz-transform: rotate(64deg);
        -ms-transform: rotate(64deg);
        -o-transform: rotate(64deg);
        position: absolute;
        left: 36px;
        top: 23px;
        z-index: 3;
        transition: all 200ms ease;
    }

    .nose {
        width: 7px;
        height: 11px;
        background-color: transparent;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        position: absolute;
        left: 53px;
        top: 70px;
        z-index: 3;
    }

    .mouth {
        position: absolute;
    }

    .mouth-line {
        width: 17px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-left-radius: 20px 4px;
        position: absolute;
        top: 81px;
        left: 27px;
        transform: rotate(44deg);
        -webkit-transform: rotate(44deg);
        -moz-transform: rotate(44deg);
        -ms-transform: rotate(44deg);
        -o-transform: rotate(44deg);
        transition: all 200ms ease;
    }

    .upper-lip {
        width: 8px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-left-radius: 7px;
        position: absolute;
        top: 80px;
        left: 49px;
        transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
        -o-transform: rotate(-60deg);
    }

    .lower-lip {
        width: 6px;
        height: 17px;
        border-top: 2px solid #000;
        border-top-left-radius: 7px;
        position: absolute;
        top: 85px;
        left: 35px;
        transform: rotate(20deg);
        -webkit-transform: rotate(20deg);
        -moz-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
        -o-transform: rotate(20deg);
    }

    .shirt#left {
        position: absolute;
        width: 70px;
        height: 70px;
        background-color: #fcee64;
        border: 2px solid #000;
        top: 73px;
        left: 67px;
        border-top-left-radius: 70px;
        border-bottom: none;
        border-right: none;
    }

    .shirt#right {
        position: absolute;
        width: 72px;
        height: 70px;
        background-color: #fcee64;
        border: 2px solid #000;
        top: 70px;
        left: 80px;
        transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        border-top-right-radius: 115px 90px;
        border-bottom-right-radius: 13px;
        border-left: none;
        z-index: -1;
    }

    .tummy {
        position: absolute;
        width: 32px;
        height: 5px;
        border-top: 2px solid #000;
        top: 120px;
        left: 129px;
        border-top-right-radius: 40px 10px;
        transform: rotate(75deg);
        -webkit-transform: rotate(75deg);
        -moz-transform: rotate(75deg);
        -ms-transform: rotate(75deg);
        -o-transform: rotate(75deg);
        z-index: 5;
    }

    .arm-left {
        position: absolute;
        width: 26px;
        height: 17px;
        border-top: 2px solid #000;
        top: 125px;
        left: 65px;
        border-right: 2px solid;
        transform: rotate(100deg);
        -webkit-transform: rotate(100deg);
        -moz-transform: rotate(100deg);
        -ms-transform: rotate(100deg);
        -o-transform: rotate(100deg);
        border-top-right-radius: 5px 9px;
        border-bottom-right-radius: 8px 5px;
        z-index: 5;
        background-color: #fcee64;
        border-bottom-left-radius: 19px;
    }

    .overalls-main {
        position: absolute;
        width: 62px;
        height: 63px;
        background-color: #cc224c;
        top: 120px;
        left: 86px;
    }

    .overalls-strap-left {
        position: absolute;
        width: 12px;
        height: 21px;
        border-right: 2px solid #000;
        border-top-right-radius: 100px;
        background-color: #cc224c;
        top: 100px;
        left: 93px;
        z-index: 1;
    }

    .overalls-strap-right {
        position: absolute;
        width: 26px;
        height: 15px;
        border-top: 2px solid;
        border-top-right-radius: 27px 14px;
        border-bottom-right-radius: 0px;
        background-color: #cc224c;
        transform: rotate(72deg);
        -webkit-transform: rotate(72deg);
        -moz-transform: rotate(72deg);
        -ms-transform: rotate(72deg);
        -o-transform: rotate(72deg);
        top: 104px;
        left: 121px;
    }

    .overalls-neck {
        position: absolute;
        width: 26px;
        height: 21px;
        background-color: #fcee64;
        border: 2px solid #000;
        top: 103px;
        left: 106px;
        transform: rotate(-6deg);
        -webkit-transform: rotate(-6deg);
        -moz-transform: rotate(-6deg);
        -ms-transform: rotate(-6deg);
        -o-transform: rotate(-6deg);
        border-left: none;
        border-top: none;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 4px;
        z-index: 0;
    }

    .button-right {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        border: 2px solid #000;
        position: absolute;
        top: 116px;
        left: 128px;
        z-index: 6;
        background-color: #FCFEA7;
    }

    .button-left {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        border: 2px solid #000;
        position: absolute;
        top: 120px;
        left: 95px;
        z-index: 6;
        background-color: #FCFEA7;
    }

    .overalls-strap-left-cover {
        width: 40px;
        height: 26px;
        border-top: 2px solid #000;
        border-right: 2px solid #000;
        border-top-right-radius: 16px;
        border-bottom-left-radius: 28px;
        position: absolute;
        top: 105px;
        left: 65px;
        background-color: #fcee64;
        transform: rotate(79deg);
        -webkit-transform: rotate(79deg);
        -moz-transform: rotate(79deg);
        -ms-transform: rotate(79deg);
        -o-transform: rotate(79deg);
        z-index: 2;
    }

    .overalls-side-right {
        width: 7px;
        height: 15px;
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        border-bottom-left-radius: 17px;
        position: absolute;
        top: 118px;
        left: 139px;
        background-color: #fcee64;
        z-index: 2;
    }

    .bok {
        width: 60px;
        height: 60px;
        border-radius: 40px;
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        position: absolute;
        top: 109px;
        left: 72px;
        z-index: -1;
        background-color: #cc224c;
    }

    .leg-left {
        width: 10px;
        height: 27px;
        border-left: 2px solid #000;
        border-bottom-left-radius: 7px;
        background-color: #cc224c;
        position: absolute;
        top: 160px;
        left: 80px;
        transform: rotate(3deg);
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
    }

    .leg-left-bottom {
        width: 38px;
        height: 5px;
        border-bottom: 2px solid #000;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        background-color: #cc224c;
        position: absolute;
        top: 181px;
        left: 82px;
        transform: rotate(-3deg);
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        z-index: 7;
    }

    .leg-middle {
        width: 10px;
        height: 30px;
        border-right: 2px solid #000;
        border-bottom-right-radius: 7px;
        background-color: #cc224c;
        position: absolute;
        top: 157px;
        left: 110px;
        transform: rotate(-1deg);
        -webkit-transform: rotate(-1deg);
        -moz-transform: rotate(-1deg);
        -o-transform: rotate(-1deg);
        -ms-transform: rotate(-1deg);
        z-index: 6;
    }

    .leg-right {
        width: 10px;
        height: 48px;
        border-right: 2px solid #000;
        border-bottom-right-radius: 7px 44px;
        background-color: #cc224c;
        position: absolute;
        top: 135px;
        left: 140px;
        transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
    }

    .leg-right-bottom {
        width: 30px;
        height: 5px;
        border-bottom: 2px solid #000;
        border-bottom-right-radius: 45px 30px;
        border-bottom-left-radius: 30px 20px;
        background-color: #cc224c;
        position: absolute;
        top: 179px;
        left: 120px;
        transform: rotate(-3deg);
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
    }

    .shoe-right {
        width: 40px;
        height: 16px;
        position: absolute;
        top: 180px;
        left: 118px;
        border: 2px solid #000;
        z-index: -1;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px 8px;
        border-bottom-left-radius: 30px 10px;
        background-color: #b4dffc;
    }

    .shoe-left {
        width: 42px;
        height: 22px;
        position: absolute;
        top: 178px;
        left: 74px;
        border: 2px solid #000;
        z-index: -1;
        border-top-left-radius: 30px 20px;
        border-bottom-left-radius: 12px 6px;
        border-bottom-right-radius: 30px 10px;
        background-color: #b4dffc;
        transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
    }

    .hand-left .palm {
        width: 9px;
        height: 8px;
        position: absolute;
        left: 75px;
        top: 145px;
        background-color: antiquewhite;
    }

    .hand-left .finger1 {
        width: 5px;
        height: 6px;
        position: absolute;
        left: 68px;
        top: 145px;
        background-color: antiquewhite;
        border: 2px solid #000;
        border-top: none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .hand-left .finger2 {
        width: 4px;
        height: 7px;
        position: absolute;
        left: 72px;
        top: 149px;
        background-color: antiquewhite;
        border: 2px solid #000;
        border-top: none;
        border-bottom-left-radius: 10px 27px;
    }

    .hand-left .finger3 {
        width: 4px;
        height: 7px;
        position: absolute;
        left: 76px;
        top: 151px;
        background-color: antiquewhite;
        border: 2px solid #000;
        border-top: none;
        border-bottom-left-radius: 10px 27px;
        border-bottom-right-radius: 12px;
    }

    .hand-left .finger4 {
        width: 5px;
        height: 4px;
        position: absolute;
        left: 80px;
        top: 148px;
        background-color: antiquewhite;
        border: 2px solid #000;
        border-top: none;
        border-bottom-left-radius: 6px 5px;
        border-bottom-right-radius: 8px 9px;
        transform: rotate(-50deg);
        -webkit-transform: rotate(-50deg);
        -moz-transform: rotate(-50deg);
        -ms-transform: rotate(-50deg);
        -o-transform: rotate(-50deg);
    }

    .hand-right .finger1 {
        width: 5px;
        height: 6px;
        position: absolute;
        left: 145px;
        top: 138px;
        background-color: antiquewhite;
        border: 2px solid #000;
        border-top: none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        z-index: -2;
    }

    .hand-right .finger2 {
        width: 4px;
        height: 13px;
        position: absolute;
        left: 151px;
        top: 130px;
        background-color: antiquewhite;
        border: 2px solid #000;
        border-top: none;
        border-bottom-left-radius: 10px 27px;
        border-bottom-right-radius: 12px;
        z-index: -2;
        transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
    }

    .wrapper:hover .eye#left .eyelid#upper {
        width: 31px;
        left: 0px;
    }

    .wrapper:hover .eye#left .eyelid#lower {
        left: -5px;
    }

    .wrapper:hover .eye#right .eyelid#upper {
        width: 30px;
        left: -4px;
    }

    .wrapper:hover .eye#right .eyelid#lower {
        left: 8px;
    }

    .wrapper:hover .eyebrow#left {
        left: 36px;
        top: 28px;
    }

    .wrapper:hover .eyebrow#right {
        left: 81px;
        top: 74px;
    }

    .wrapper:hover .mouth-line {
        border-top-left-radius: 14px 7px;
    }

    .wrapper:hover .head {
        top: 3px;
    }

    .wrapper:hover .ear#right {
        top: 45px;
    }
}
</style>
