module.exports = {

  // https://cli.vuejs.org/config/#publicpath

  //=============
  publicPath: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PATH : '/',
  //=============

  lintOnSave: false,
  css: {
    loaderOptions: {
      sass: {
        additionalData: '@import "@/assets/css/template-base.scss";@import "@/assets/css/' + process.env.VUE_APP_TEMPLATE_CSS + '";'
      }
    }
  },
  devServer: { //https://github.com/vuejs/vue-cli/issues/2051#issuecomment-420320469
    watchOptions: {
      // poll: true
    }
  },
  pluginOptions: {
    webpackBundleAnalyzer: {
      analyzerMode: "disabled"
    }
  }
};