import { render, staticRenderFns } from "./VideoYoutube.vue?vue&type=template&id=4a761975&scoped=true&v-if=(this.%24store.state.currentCard%5B'Youtube%20Video%20Id'%5D)&"
import script from "./VideoYoutube.vue?vue&type=script&lang=js&"
export * from "./VideoYoutube.vue?vue&type=script&lang=js&"
import style0 from "./VideoYoutube.vue?vue&type=style&index=0&id=4a761975&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a761975",
  null
  
)

export default component.exports